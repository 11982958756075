import {SchemeListHeader} from './components/header/SchemeListHeader'
import {SchemeTable} from './table/SchemeTable'
import {KTCard} from '../../../../../_metronic/helpers'

import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ContextProvider} from './ContextProvider'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message/AlertBox'
// import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
// import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

const SchemeList = () => {
  return (
    <>
      <KTCard>
        <SchemeListHeader />
        <SchemeTable />
      </KTCard>
    </>
  )
}

const SchemeMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}

      <SchemeList />
    </div>
  )
}

const SchemeListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <SchemeMain />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SchemeListWrapper}
