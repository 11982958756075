import {FC, useEffect, useState} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import {AlertContext} from './index'

const AlertProvider: FC<WithChildren> = ({children}) => {
  const [alert, setAlert] = useState({show: false, message: '', type: ''})
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (alert.show) {
      timeout = setTimeout(() => {
        hideAlert(false)
      }, 5000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [alert.show])
  const showAlert = (message: string, type: any) => {
    console.log('hi', message, type)
    setAlert({show: true, message: message, type: type})
  }

  const hideAlert = (hide: boolean) => {
    const btn = document.getElementById('alertBox')
    if (btn) {
      btn.setAttribute('style', 'display:none!important;')
    }
    setAlert({show: false, message: ' ', type: ' '})
  }
  //console.log("alerttt",alert);
  return (
    <AlertContext.Provider
      value={{
        show: alert.show,
        message: alert.message,
        type: alert.type,
        showAlert,
        hideAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
