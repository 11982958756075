import {useListView} from '../../core/ListViewProvider'
import {UsersListSearchComponent} from './MakkalListSearchComponent'
import {MakkalListToolbar} from './MakkalListToolbar'

const MakkalListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* Search bar */}
      <UsersListSearchComponent />
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <MakkalListToolbar />
        {/* end::Group actions */}
      </div>
    </div>
  )
}

export {MakkalListHeader}
