import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {GrievancesListHeader} from './components/header/GrievancesListHeader'
import {GrievancesTable} from './table/GrievancesTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
import {useContext} from 'react'

const GrievancesList = () => {
  return (
    <>
      <KTCard>
        <GrievancesListHeader />
        <GrievancesTable />
      </KTCard>
    </>
  )
}
const GrievancesMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}
      <div className='col-lg-12'>
        <GrievancesList />
      </div>
    </div>
  )
}
const GrievancesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <GrievancesMain />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {GrievancesListWrapper}
