import {useListView} from '../../core/ListViewProvider'
import {UsersListSearchComponent} from './SchemeListSearchComponent'
import {SchemeListToolbar} from './SchemeListToolbar'

const SchemeListHeader = () => {
  const {selected} = useListView()
  return (
    <>
      <div className='card-header border-0 pt-6'>
        {/* Search bar */}
        <UsersListSearchComponent />
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          <SchemeListToolbar />
          {/* end::Group actions */}
        </div>
      </div>
    </>
  )
}

export {SchemeListHeader}
