import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {viewEmployee} from '../employee-list/core/_requests'
import {EditEmployee} from '../employee-form/EditEmployee'

export const EditEmployeeWrapper = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    viewEmployee(id).then((res) => {
      console.log('res', res)
      setData(res.data)
    })
  }, [])
  if (data) {
    return <EditEmployee initialValues={data} />
  }
  return null
}
