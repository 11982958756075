// @ts-nocheck
import {Column} from 'react-table'
import {CategoryInfoCell} from './CategoryInfoCell'
import {CategoryActionsCell} from './CategoryActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {Category} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<Category>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='S.No' className='min-w-125px' />,
    accessor: 'serialNo',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
    Cell: ({...props}) => <CategoryInfoCell category={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='incharge' className='min-w-125px' />
    ),
    accessor: 'employee.name',
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CategoryActionsCell category={props.data[props.row.index]} />,
  },
]

export {usersColumns}
