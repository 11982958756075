import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createEmployee, getRole} from '../employee-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../employee-list/core/QueryResponseProvider'

//Model Interface
export interface IProfileDetails {
  name: string
  roleId: string
  employeeId: string
  email: string
  mobile: string
  address: string
  password: string
  profile: any
  formImage: any
  createdBy: number | undefined
}

//Model Initial Value
export let initialValues: IProfileDetails = {
  name: '',
  roleId: '',
  employeeId: '',
  email: '',
  profile: '',
  formImage: '',
  mobile: '',
  address: '',
  password: '',
  createdBy: 0,
}

//Validation
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Employee name is required'),
  roleId: Yup.string().required('Role is required'),

  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Invalid mobile number')
    .required('Mobile number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  address: Yup.string().required('Address is required'),
  password: Yup.string().required('Employee password is required'),
})

//Function Starts
const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()
  const [formImage, setFormImage] = useState<File | null>(null)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //Image Handler
  const imageHandler = (e: Event<HTMLInputElement>) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({
          ...data,
          profile: reader.result,
        })
      }
    }
    const files = e.target.files
    if (files) {
      setFormImage(files[0])
      reader.readAsDataURL(files[0])
    }
  }

  //Get Current User in useAuth() to get createdBy, type
  const {currentUser} = useAuth()
  // const type = currentUser?.type
  const [loading, setLoading] = useState(false)
  //formik Starts
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,

    //Form Submit
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      const updatedData = Object.assign(data, values)
      // updatedData.createdBy = currentUser?.userId

      //Image and data Append to Form Data
      const formData = new FormData()
      //image append to formData
      if (formImage) formData.append('profile', formImage)

      //updatedData append to formData
      Object.entries(updatedData).forEach(([key, value]) => formData.append(key, value))

      createEmployee(formData).then((res) => {
        console.log('dsdsds', res)
        initialValues = {
          name: '',
          roleId: '',
          email: '',
          employeeId: '',
          profile: '',
          formImage: '',
          mobile: '',
          address: '',
          password: '',
          createdBy: 0,
        }
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/employee', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    },
    enableReinitialize: true,
  })

  // Get role using type
  const [role, setRole] = useState<any>([])
  useEffect(() => {
    getRole().then((res: any) => {
      setRole(res.data.data)
      console.log('editRoleres=>', res.data.data)
    })
  }, [])
  console.log('formik', formik)
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Employee </h3>
        </div>
      </div>

      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            {/** Profile Image */}
            <div className='row mb-6'>
              <div className='col-lg-8'>
                <label className='col-lg-12 col-form-label fw-bold fs-6'>Employee Picture</label>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  {typeof data?.profile === 'string' ? (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{backgroundImage: `url(${data.profile})`}}
                    ></div>
                  ) : (
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                      }}
                    ></div>
                  )}
                  <label
                    className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    data-bs-dismiss='click'
                    title='Change Profile image'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>
                    <input
                      type='file'
                      name='avatar'
                      onChange={imageHandler}
                      accept='.png, .jpg, .jpeg'
                    />
                  </label>
                  {/* <!--end::Edit button--> */}
                  {/* <!--begin::Remove button--> */}
                  {data.profile && (
                    <span
                      className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      onClick={() => {
                        setData({
                          ...data,
                          profile: null,
                        })
                      }}
                      data-bs-toggle='tooltip'
                      data-bs-dismiss='click'
                      title='Remove Profile image'
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                  )}
                </div>
                {data.profile ? (
                  ''
                ) : (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>Please upload profile</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Role</label>
                  <select
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Role'
                    {...formik.getFieldProps('roleId')}
                  >
                    <option value=''>Select a Role</option>
                    {role.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.roleName}
                        </option>
                      )
                    })}
                  </select>
                  {formik.touched.roleId && formik.errors.roleId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.roleId}</div>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Name'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Mobile Number</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Mobile Number'
                    {...formik.getFieldProps('mobile')}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.mobile}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Email</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Email'
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Password</label>
                  <input
                    type='password'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee password'
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Address</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Employee Address'
                    {...formik.getFieldProps('address')}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.address}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails
