import React, {useEffect} from 'react'
import {Navigate, Route, Routes, Outlet, useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {Settings} from './components/settings/Settings'

interface propStates {
  Psuccess: boolean
  Pmessage: string
}
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            {/* <AccountHeader /> */}
            <Outlet />
          </>
        }
      >
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Profile Overview</PageTitle>
              <Settings />
            </>
          }
        />

        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default AccountPage
