import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {GrievancesListWrapper} from './grievances-list/GrievancesList'

const grievancesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Grievances Management',
    path: '/grievances',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const GrievancessPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={grievancesBreadcrumbs}>Grievances list</PageTitle>
              <GrievancesListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
    
     
    </Routes>
  )
}

export default GrievancessPage
