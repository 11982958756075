import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Scheme, SchemesQueryResponse} from './_models'
// import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URL

//Scheme API URL
const CREATE_Scheme_URL = `${API_URL}/scheme/add`
const GET_Scheme_URL = `${API_URL}/scheme/query`
const UPDATE_Scheme_URL = `${API_URL}/scheme/update`
const DELETE_Scheme_URL = `${API_URL}/scheme/delete`

// var AuthVerify = getToken.call(this)

//Scheme LIST
const getScheme = (query: string): Promise<SchemesQueryResponse> => {
  return axios
    .post(`${GET_Scheme_URL}?${query}`,
     // AuthVerify
    )
    .then((response: AxiosResponse<SchemesQueryResponse>) => {
      return response.data
    })
}

//Scheme ADD
const createScheme = (data: Scheme): Promise<Scheme | undefined> => {
  return axios.post(CREATE_Scheme_URL, data,
    //getToken()
  ).then((response: any) => response.data)
}

//Scheme UPDATE
const updateScheme = (data: any, id: any) => {
  return axios.put(`${UPDATE_Scheme_URL}/${id}`, data ,
    //getToken()
  ).then((response) => {
    return response.data
  })
}

//Scheme DELETE
const deleteSchemeById = (id: any) => {
  return axios.post(`${DELETE_Scheme_URL}/${id}`,
    //AuthVerify


    ).then((response) => {
    return response.data
  })
}

export {getScheme, createScheme, updateScheme, deleteSchemeById}
