import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Role, RolesQueryResponse} from './_models'
 import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URL

//ROLE API URL
const CREATE_ROLE_URL = `${API_URL}/roles/add`
const GET_ROLE_URL = `${API_URL}/roles/query`
const UPDATE_ROLE_URL = `${API_URL}/roles/update`
const DELETE_ROLE_URL = `${API_URL}/roles/delete`

// var AuthVerify = getToken.call(this)

//ROLE LIST
const getRole = (query: string): Promise<RolesQueryResponse> => {
  return axios
    .post(`${GET_ROLE_URL}?${query}`,
     // AuthVerify
    )
    .then((response: AxiosResponse<RolesQueryResponse>) => {
      return response.data
    })
}

//ROLE ADD
const createRole = (data: Role): Promise<Role | undefined> => {
  return axios.post(CREATE_ROLE_URL, data,
    getToken()
  ).then((response: any) => response.data)
}

//ROLE UPDATE
const updateRole = (name: any, id: any, updatedBy: any) => {
  return axios.put(`${UPDATE_ROLE_URL}/${id}`, { name, updatedBy },
    //getToken()
  ).then((response) => {
    return response.data
  })
}

//ROLE DELETE
const deleteRoleById = (id: any) => {
  return axios.post(`${DELETE_ROLE_URL}/${id}`,
    //AuthVerify


    ).then((response) => {
    return response.data
  })
}

export {getRole, createRole, updateRole, deleteRoleById}
