import {RoleaccessContent} from './table/RoleaccessContent'
import {KTCard} from '../../../../../_metronic/helpers'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useContext} from 'react'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

const Roleaccess = () => {
  const {show, message, type} = useContext(AlertContext)
  console.log('show', show)
  return (
    <>
      <KTCard>
        {show && <AlertBox type={type == '1' ? 'success' : 'error'} message={message} />}
        <RoleaccessContent />
      </KTCard>
    </>
  )
}

const RoleaccessWrapper = () => <Roleaccess />

export {RoleaccessWrapper}
