// @ts-nocheck
import {Column} from 'react-table'
import {SchemeInfoCell} from './SchemeInfoCell'
import {SchemeActionsCell} from './SchemeActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {Scheme} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<Scheme>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='S.No' className='min-w-125px' />,
    accessor: 'serialNo',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
    Cell: ({...props}) => <SchemeInfoCell scheme={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Link' className='min-w-125px' />,
    accessor: 'link',
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SchemeActionsCell scheme={props.data[props.row.index]} />,
  },
]

export {usersColumns}
