import React, {useContext} from 'react'
import {ProfileDetails} from './cards/ProfileDetails'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

export function Settings() {
  const {show, message, type} = useContext(AlertContext)
  return (
    <>
      {' '}
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}
      <ProfileDetails />
    </>
  )
}
