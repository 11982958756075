/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Employee} from '../../core/_models'

type Props = {
  employees: Employee
}

const EmployeeInfoCell: FC<Props> = ({employees}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        <img
          src={employees?.profile}
          alt={employees.name}
          style={{width: '50px', height: '50px'}}
        />
      </a>
    </div>
  </div>
)

export {EmployeeInfoCell}
