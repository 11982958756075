import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {MakkalListFilter} from './MakkalListFilter'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../../auth'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'
import {Button, Modal} from 'react-bootstrap'
import {createMakkal} from '../../core/_requests'

const MakkalListToolbar = () => {
  const {showAlert} = useContext(AlertContext)
  const {setItemIdForUpdate} = useListView()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {refetch} = useQueryResponse()
  const [showModal, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [ErrorName, setErrorName] = useState('')
  const [ErrorEmp, setErrorLink] = useState('')
  const [link, setLink] = useState('')
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 9).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  const handleClose = () => {
    setShow(false)
  }
  const openAddUserModal = () => {
    setShow(true)
  }

  const submitData = () => {
    const updatedData = {
      name: name,
      link: link,
      createdBy: currentUser?.userId,
    }
    if (name != '' && link != '') {
      setLoading(true)
      createMakkal(updatedData).then((res: any) => {
        console.log('Alertres', res)

        navigate('/makkal', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    } else {
      if (name === '') {
        setErrorName('MSM name is required')
      }
      if (link === '') {
        setErrorLink('Link is required')
      }
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {roleaccess.add === 1 ? (
          <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add New
          </button>
        ) : (
          ''
        )}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Makkal Sevai Mayyam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>
                  Makkal Sevai Mayyam Name
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Makkal Sevai Mayyam  Name'
                  id='Name'
                  onChange={(e: any) => {
                    setName(e.target.value)
                    setErrorName('')
                  }}
                />
                {ErrorName ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorName}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>
                  Makkal Sevai Mayyam Link
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter  Makkal Sevai Mayyam  Link'
                  id='Link'
                  onChange={(e: any) => {
                    setLink(e.target.value)
                    setErrorLink('')
                  }}
                />
                {ErrorEmp ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorEmp}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={submitData}>
            {!loading ? 'Submit' : 'Loading...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {MakkalListToolbar}
