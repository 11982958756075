import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Employee, EmployeeQueryResponse} from './_models'
// import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URL

//ROLE API URL
const CREATE_EMPLOYEE_URL = `${API_URL}/employee/add`
const GET_EMPLOYEE_URL = `${API_URL}/employee/query`
const VIEW_EMPLOYEE_URL = `${API_URL}/employee/view`
const UPDATE_EMPLOYEE_URL = `${API_URL}/employee/update`
const DELETE_EMPLOYEE_URL = `${API_URL}/employee/delete`
const GET_ROLE_URL = `${API_URL}/employee/getRole`

//AuthVerify
// var AuthVerify = getToken.call(this)

//EMPLOYEE LIST
const getEmployee = (query: string): Promise<EmployeeQueryResponse> => {
  return axios
    .get(`${GET_EMPLOYEE_URL}?${query}`,
     // getToken()
    )
    .then((response: AxiosResponse<EmployeeQueryResponse>) => {
      return response.data
    })
}

//EMPLOYEE ADD
const createEmployee = (data: FormData): Promise<Employee | undefined> => {
  return axios.post(CREATE_EMPLOYEE_URL, data,
    //getToken()
  ).then((response: any) => response.data)
}

//EMPLOYEE VEIW
const viewEmployee = (id: any) => {
  return axios.get(`${VIEW_EMPLOYEE_URL}/${id}`,
    //getToken()
  ).then((response) => {
    return response.data
  })
}

//EMPLOYEE UPDATE
const updateEmployee = (data: FormData, id: any) => {
  console.log('reqData', data)
  return axios.put(`${UPDATE_EMPLOYEE_URL}/${id}`, data,
   // getToken()
  ).then((response) => {
    return response.data
  })
}

//EMPLOYEE DELETE
const deleteEmployeeById = (id: any) => {
  return axios.post(`${DELETE_EMPLOYEE_URL}/${id}`,
    //AuthVerify
  ).then((response) => {
    return response.data
  })
}

//GET ROLE
const getRole = async () => {
  const res = await axios.get(`${GET_ROLE_URL}`,
  //  getToken()
  )
  console.log('roleList=>', res)
  return res
}

export {getEmployee, createEmployee, viewEmployee, updateEmployee, deleteEmployeeById, getRole}
