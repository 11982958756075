/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Grievances} from '../../core/_models'

type Props = {
  grievances: Grievances
}

const GrievancesInfoCell: FC<Props> = ({grievances}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {/* {grievances.profile ? (
          <div className='symbol-label'>
            <img
              src={toAbsoluteUrl(`/media/${grievances.profile}`)}
              alt={grievances.name}
              className='w-100'
            />
          </div>
        ) : ( */}
      
        {/* )} */}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {/* {grievances.name} */}
      </a>
      {/* <span>{grievances.email}</span> */}
    </div>
  </div>
)

export {GrievancesInfoCell}
