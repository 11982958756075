import {MakkalListHeader} from './components/header/MakkalListHeader'
import {MakkalTable} from './table/MakkalTable'
import {KTCard} from '../../../../../_metronic/helpers'

import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ContextProvider} from './ContextProvider'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
// import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
// import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'

const MakkalList = () => {
  return (
    <>
      <KTCard>
        <MakkalListHeader />
        <MakkalTable />
      </KTCard>
    </>
  )
}

const MakkalMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}

      <MakkalList />
    </div>
  )
}

const MakkalListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <MakkalMain />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MakkalListWrapper}
