import React, {useState, useContext, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {createRole} from '../role-list/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../role-list/core/QueryResponseProvider'
import {getExCRUD} from '../../role-access/role-setting-list/core/_requests'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import {getExCRUD} from '../../role-access/role-setting-list/core/_requests'

export interface IProfileDetails {
  name: string
  createdBy: string | undefined
}

export let initialValues: IProfileDetails = {
  name: '',
  createdBy: '',
}
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Role name is required'),
})

const RoleDetails: React.FC = () => {
  const {showAlert} = useContext(AlertContext)
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const navigate = useNavigate()

  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,
    onSubmit: (values, {resetForm}) => {
      if (roleaccess?.add === 1) {
        setLoading(true)

        const updatedData = Object.assign(data, values)

        updatedData.createdBy = currentUser?.userId
        createRole(updatedData).then((res: any) => {
          console.log('Alertres', res?.Message, res?.Code)
          initialValues = {
            name: '',

            createdBy: '',
          }
          resetForm({
            ...initialValues,
            values: {...initialValues},
          })
          setData(initialValues)
          navigate('/role/list', {replace: true})
          refetch()
          setLoading(false)
          showAlert(res?.Message, res?.Code)
        })
      } else {
        toast.error('You do not have permission to access this page', {
          position: 'top-right',
          style: {
            backgroundColor: '#9b2313',
            color: '#c7c7d7',
            fontSize: '14px',
          },
          closeButton: false,
        })
      }
    },
    enableReinitialize: true,
  })
  // console.log(data?.profileImage+"prof");
  const [roleaccess, setroleaccess] = useState<any>([])
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 2).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Add Role </h3>
        </div>
      </div>
      <ToastContainer />
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/** Company Info */}
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-form-label required fw-bold fs-6'>Role Name</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Enter Role Name'
                    id='roleName'
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RoleDetails
