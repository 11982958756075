import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Makkal, MakkalsQueryResponse} from './_models'
 import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URL

//Makkal API URL
const CREATE_Makkal_URL = `${API_URL}/makkalsevai/add`
const GET_Makkal_URL = `${API_URL}/makkalsevai/query`
const UPDATE_Makkal_URL = `${API_URL}/makkalsevai/update`
const DELETE_Makkal_URL = `${API_URL}/makkalsevai/delete`
console.log("token",getToken());


//Makkal LIST
const getMakkal = (query: string): Promise<MakkalsQueryResponse> => {
  return axios
    .post(`${GET_Makkal_URL}?${query}`,
     // AuthVerify
    )
    .then((response: AxiosResponse<MakkalsQueryResponse>) => {
      return response.data
    })
}

//Makkal ADD
const createMakkal = (data: any)=> {
  console.log("dsdfsdfdf");
  return axios.post(CREATE_Makkal_URL, data,
    getToken()
  ).then((response: any) => response.data)
}

//Makkal UPDATE
const updateMakkal = (name: any, id: any, updatedBy: any) => {
  return axios.put(`${UPDATE_Makkal_URL}/${id}`, { name, updatedBy },
    //getToken()
  ).then((response) => {
    return response.data
  })
}

//Makkal DELETE
const deleteMakkalById = (id: any) => {
  return axios.post(`${DELETE_Makkal_URL}/${id}`,
    //AuthVerify


    ).then((response) => {
    return response.data
  })
}

export {getMakkal, createMakkal, updateMakkal, deleteMakkalById}
