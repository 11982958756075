/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {deleteSchemeById, updateScheme} from '../../core/_requests'
import {Scheme} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {MyContext} from '../../ContextFile'
import React from 'react'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'

// import {getExCRUD} from '../../../../scheme-access/scheme-setting-list/core/_requests'
type Props = {
  scheme: any
}

const SchemeActionsCell: FC<Props> = ({scheme}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [showEModal, setEShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(scheme?.name)
  const [ErrorName, setErrorName] = useState('')
  const [ErrorLink, setErrorLink] = useState('')
  const [link, setLink] = useState(scheme?.link)
  const handleEClose = () => {
    setEShow(false)
  }
  const deleteItem = () =>
    deleteSchemeById(scheme.primaryId).then((res) => {
      setShow(false)
      showAlert(res?.Message, res?.Code)
      navigate('/scheme')
    })
  const {state, setState} = React.useContext(MyContext)
  const {currentUser} = useAuth()
  const [roleaccess, setroleaccess] = useState<any>([])
  const {refetch} = useQueryResponse()
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 8).then((res: any) => {
      var crudres = res.data?.Data[0]
      setroleaccess(crudres)
    })
  }, [])

  const handleShow = () => {
    if (roleaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      setShow(true)
    }
  }
  const [employee, setEmployee] = useState<any>([])

  const submitData = () => {
    const updatedData = {
      name: name,
      link: link,
      updatedBy: currentUser?.userId,
    }
    if (name != '' && link != '') {
      setLoading(true)

      updateScheme(updatedData, scheme?.primaryId).then((res: any) => {
        console.log('Alertres', res)

        navigate('/scheme', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    } else {
      if (name === '') {
        setErrorName('Category name is required')
      }
      if (link === '') {
        setErrorLink('Link is required')
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        {roleaccess?.edit === 1 && (
          <a
            onClick={() => {
              setEShow(true)
            }}
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </a>
        )}

        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>

      {/* Modal */}

      {/* Modal */}
      <Modal show={showEModal} onHide={handleEClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Scheme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Scheme Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Scheme  Name'
                  id='Name'
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value)
                    setErrorName('')
                  }}
                />
                {ErrorName ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorName}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Link</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Scheme  Link'
                  id='Name'
                  value={link}
                  onChange={(e: any) => {
                    setLink(e.target.value)
                    setErrorLink('')
                  }}
                />
                {ErrorLink ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorLink}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleEClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={submitData}>
            {!loading ? 'Submit' : 'Loading...'}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{scheme.name}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {SchemeActionsCell}
