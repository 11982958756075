import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Category, CategorysQueryResponse} from './_models'
 import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URL

//Category API URL
const CREATE_Category_URL = `${API_URL}/category/add`
const GET_Category_URL = `${API_URL}/category/query`
const UPDATE_Category_URL = `${API_URL}/category/update`
const DELETE_Category_URL = `${API_URL}/category/delete`

// var AuthVerify = getToken.call(this)

//Category LIST
const getCategory = (query: string): Promise<CategorysQueryResponse> => {
  return axios
    .post(`${GET_Category_URL}?${query}`,
      getToken()
    )
    .then((response: AxiosResponse<CategorysQueryResponse>) => {
      return response.data
    })
}

//Category ADD
const createCategory = (data: Category): Promise<Category | undefined> => {
  return axios.post(CREATE_Category_URL, data,
    //getToken()
  ).then((response: any) => response.data)
}

//Category UPDATE
const updateCategory = (data:any,id:any) => {
  return axios.put(`${UPDATE_Category_URL}/${id}`, data,
    //getToken()
  ).then((response) => {
    return response.data
  })
}

//Category DELETE
const deleteCategoryById = (id: any) => {
  return axios.post(`${DELETE_Category_URL}/${id}`,
    //AuthVerify


    ).then((response) => {
    return response.data
  })
}
const getEmployee=()=>{
  return axios.get(`${API_URL}/employee/getEmployee`,
    //AuthVerify


    ).then((response) => {
    return response.data
  })
}
export {getCategory, createCategory, updateCategory, deleteCategoryById,getEmployee}
