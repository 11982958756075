/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

import {useAuth} from '../../modules/auth'
import {StatisticsWidget5} from './StatisticsWidget5'
import {VisitsGraph} from './VisitsGraph'

const DashboardWrapper: FC = () => {
  const [count, setCount] = useState<any>([])

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#F1416C'
            iconColor='white'
            title='0'
            description='Total Grievance'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#e38e12'
            iconColor='white'
            title='0'
            description='Completed Grievance'
            img={toAbsoluteUrl('/media/patterns/vector-3.png')}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#3bbaf3ed'
            iconColor='white'
            title='0'
            description='Pending Grievance'
            img={toAbsoluteUrl('/media/patterns/vector-blue.png')}
          />
        </div>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#df73f1'
            iconColor='white'
            title='0'
            description='Processing Grievance'
            img={toAbsoluteUrl('/media/patterns/vector-4.png')}
          />
        </div>
      </div>

      {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
      <Widget className='h-md-50 mb-5 mb-xl-10'  /> 
     <Widget1 className='h-md-50 mb-5 mb-xl-10' /> 
      </div> */}

      {/* end::Col */}

      {/* end::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className=' card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#FF7043'
            iconColor='white'
            title='0'
            description='Total Department'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#F06292'
            iconColor='white'
            title='0'
            description='Today Category'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className=' card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#64B5F6'
            iconColor='white'
            title='0'
            description='Total Schemes'
            img={toAbsoluteUrl('/media/patterns/vector-blue.png')}
          />
        </div>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#6a7acf'
            iconColor='white'
            title='0'
            description='Total MNM'
            img={toAbsoluteUrl('/media/patterns/vector-5.png')}
          />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <VisitsGraph className='card-xxl-stretch mb-xl-3' />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

export {DashboardWrapper}
