import {Route, Routes, Outlet, useNavigate, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {RoleaccessWrapper} from './role-setting-list/RoleaccessWrapper'

import {KTSVG} from '../../../../_metronic/helpers'
import {AlertContext} from '../../../../_metronic/layout/components/alert-context'
import {useContext, useEffect} from 'react'

const cpaymentBreadcrumbs: Array<PageLink> = [
  {
    title: 'Role Access',
    path: '/page-access',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const RoleaccessPage = () => {
  const navigate = useNavigate()

  const {hideAlert} = useContext(AlertContext)
  useEffect(() => {
    hideAlert(false)
  }, [])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={cpaymentBreadcrumbs}>Role Access </PageTitle>

              <RoleaccessWrapper />
            </>
          }
        />
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  )
}

export default RoleaccessPage
