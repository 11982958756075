/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {deleteCategoryById, getEmployee, updateCategory} from '../../core/_requests'
import {Category} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {MyContext} from '../../ContextFile'
import React from 'react'
import {useAuth} from '../../../../../auth'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {AlertContext} from '../../../../../../../_metronic/layout/components/alert-context'
import {getExCRUD} from '../../../../role-access/role-setting-list/core/_requests'

// import {getExCRUD} from '../../../../category-access/category-setting-list/core/_requests'
type Props = {
  category: any
}

const CategoryActionsCell: FC<Props> = ({category}) => {
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [showEModal, setEShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(category?.name)
  const [ErrorName, setErrorName] = useState('')
  const [ErrorEmp, setErrorEmp] = useState('')
  const [incharge, setIncharge] = useState(category?.employeeId)
  const handleEClose = () => {
    setEShow(false)
  }
  const deleteItem = () =>
    deleteCategoryById(category.primaryId).then((res) => {
      setShow(false)
      showAlert(res?.Message, res?.Code)
      navigate('/category')
    })
  const {state, setState} = React.useContext(MyContext)
  const {currentUser} = useAuth()
  const [categoryaccess, setcategoryaccess] = useState<any>([])
  const {refetch} = useQueryResponse()
  useEffect(() => {
    getExCRUD(currentUser?.roleId, 6).then((res: any) => {
      var crudres = res.data?.Data[0]
      setcategoryaccess(crudres)
    })
  }, [])

  const handleShow = () => {
    if (categoryaccess?.remove === 0) {
      toast.error('You do not have permission to access this page', {
        position: 'top-right',
        style: {
          backgroundColor: '#9b2313',
          color: '#c7c7d7',
          fontSize: '14px',
        },
        closeButton: false,
      })
    } else {
      setShow(true)
    }
  }
  const [employee, setEmployee] = useState<any>([])
  useEffect(() => {
    getEmployee().then((res: any) => {
      setEmployee(res.data)
    })
  }, [])

  const submitData = () => {
    const updatedData = {
      name: name,
      incharge: incharge,
      updatedBy: currentUser?.userId,
    }
    if (name != '' && incharge != '') {
      setLoading(true)

      updateCategory(updatedData, category?.primaryId).then((res: any) => {
        console.log('Alertres', res)

        navigate('/category', {replace: true})
        refetch()
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    } else {
      if (name === '') {
        setErrorName('Category name is required')
      }
      if (incharge === '') {
        setErrorEmp('Incharge is required')
      }
    }
  }
  return (
    <>
      <ToastContainer />
      <div className='d-flex justify-content-end flex-shrink-0'>
        {categoryaccess?.edit === 1 && (
          <a
            onClick={() => {
              console.log('handle', category, state)
              setEShow(true)
            }}
            href='#'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </a>
        )}

        <a
          href='#'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={handleShow}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
      <Modal show={showEModal} onHide={handleEClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-6'>
            <div className='row'>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Category Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Enter Category  Name'
                  id='Name'
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value)
                    setErrorName('')
                  }}
                />
                {ErrorName ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorName}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='col-lg-12 fv-row'>
                <label className='col-form-label required fw-bold fs-6'>Incharge</label>
                <select
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Incharge'
                  value={incharge}
                  onChange={(e: any) => {
                    setIncharge(e.target.value)
                    setErrorEmp('')
                  }}
                >
                  <option value=''>Select Incharge</option>
                  {employee.map((item: any, i: any) => {
                    return <option value={item?.id}>{item?.name}</option>
                  })}
                </select>
                {ErrorEmp ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{ErrorEmp}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleEClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={submitData}>
            {!loading ? 'Submit' : 'Loading...'}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Header>Are you sure you want to delete "{category.name}"?</Modal.Header>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Not Now
          </Button>
          <Button variant='primary' onClick={deleteItem}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
    </>
  )
}

export {CategoryActionsCell}
