import React, {FC, useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getRole, updateEmployee} from '../employee-list/core/_requests'
import {Employee} from '../employee-list/core/_models'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'

const imageUrl = process.env.REACT_APP_IMAGE_UPLOADS
const clinicDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Employee name is required'),
  email: Yup.string().required('Employee email is required'),
  address: Yup.string().required('Employee Address is required'),
  roleId: Yup.string().required('Employee role is required'),
  mobile: Yup.string().required('Employee mobile  is required'),
  password: Yup.string(),
})

type Props = {
  initialValues: Employee
}
export const EditEmployee: FC<Props> = ({initialValues}) => {
  const [data, setData] = useState<Employee>(initialValues)
  const {showAlert} = useContext(AlertContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [role, setRole] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [formImage, setFormImage] = useState<File | null>(null)

  //Image Target
  interface Event<T = EventTarget> {
    target: T
    // ...
  }

  //Image Handler
  const imageHandler = (e: Event<HTMLInputElement>) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({
          ...data,
          profileNew: reader.result,
        })
      }
    }
    const files = e.target.files
    if (files) {
      setFormImage(files[0])
      reader.readAsDataURL(files[0])
    }
  }
  //get type
  const {currentUser} = useAuth()

  // const type = currentUser?.type
  //end get type
  const formik = useFormik<Employee>({
    initialValues,
    validationSchema: clinicDetailsSchema,
    onSubmit: (values, actions) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)

        //Image and data Append to Form Data
        const formData = new FormData()
        //image append to formData
        if (formImage) {
          formData.append('profileNew', formImage)
        } else {
          const oldImage = initialValues.profile // Assuming initialValues is defined elsewhere
          if (oldImage !== undefined) {
            formData.append('profileNew', oldImage)
          }
        }
        //append other data's in formData
        Object.entries(updatedData).forEach(([key, value]) => formData.append(key, value))

        updateEmployee(formData, id).then((res) => {
          console.log(res)
          showAlert(res?.Message, res?.Code)
          navigate('/employee', {replace: true})
        })
        setLoading(false)
      }, 1000)
    },
  })
  useEffect(() => {
    getRole().then((res: any) => {
      setRole(res.data.data)
    })
  }, [])
  console.log(formik)
  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card mb-5 mb-xl-10 p-9'>
        {/** Company Info */}
        {/** Profile Image */}
        <div className='row mb-6'>
          <div className='col-lg-8'>
            <label className='col-lg-12 col-form-label fw-bold fs-6'>Employee Picture</label>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: `url(${initialValues?.profile})`}}
            >
              {typeof data?.profileNew === 'string' ? (
                <div
                  className='image-input-wrapper w-125px h-125px'
                  style={{backgroundImage: `url(${data.profileNew})`}}
                ></div>
              ) : (
                <div
                  className='image-input-wrapper w-125px h-125px'
                  style={{
                    backgroundImage: `url(${initialValues?.profile})`,
                  }}
                ></div>
              )}
              <label
                className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                data-bs-dismiss='click'
                title='Change Profile image'
              >
                <i className='bi bi-pencil-fill fs-7'></i>
                <input
                  type='file'
                  name='avatar'
                  onChange={imageHandler}
                  accept='.png, .jpg, .jpeg'
                />
              </label>
              {/* <!--end::Edit button--> */}
              {/* <!--begin::Remove button--> */}
              {data.profileNew && (
                <span
                  className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='remove'
                  onClick={() => {
                    setData({
                      ...data,
                      profileNew: null,
                    })
                  }}
                  data-bs-toggle='tooltip'
                  data-bs-dismiss='click'
                  title='Remove Profile image'
                >
                  <i className='bi bi-x fs-2'></i>
                </span>
              )}
            </div>
            {data.profile ? (
              ''
            ) : (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>Please upload profile</div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-6'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Role</label>
              <select
                className='form-control form-control-lg form-control-solid'
                placeholder='Role'
                {...formik.getFieldProps('roleId')}
              >
                <option value=''>Select a Role</option>
                {role.map((item: any, i: any) => {
                  return (
                    <option key={i} value={item.id}>
                      {item.roleName}
                    </option>
                  )
                })}
              </select>
              {formik.touched.roleId && formik.errors.roleId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.roleId}</div>
                </div>
              )}
            </div>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Name</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Employee Name'
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Mobile Number</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Employee Mobile Number'
                {...formik.getFieldProps('mobile')}
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.mobile}</div>
                </div>
              )}
            </div>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Email</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Employee Email'
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='row'>
            <div className='col-lg-6 fv-row'>
              <label className='col-form-label required fw-bold fs-6'>Address</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Enter Employee Address'
                {...formik.getFieldProps('address')}
              />
              {formik.touched.address && formik.errors.address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default EditEmployee
