import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import EmployeePage from '../modules/apps/employee/EmployeePage'
import RolePage from '../modules/apps/role/RolePage'
import DepartmentPage from '../modules/apps/department/DepartmentPage'
import CategoryPage from '../modules/apps/category/CategoryPage'
import SchemePage from '../modules/apps/scheme/SchemePage'
import MakkalPage from '../modules/apps/makkal/MakkalPage'
import GrievancesPage from '../modules/apps/grievances/GrievancesPage'
import RoleaccessPage from '../modules/apps/role-access/RoleaccessPage'
import AccountPage from '../modules/accounts/AccountPage'
import PasswordPage from '../modules/accounts/PasswordPage'

const PrivateRoutes = () => {
  // const RolePage = lazy(() => import('../modules/apps/role/RolePage'))

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />

          <Route
            path='role/*'
            element={
              <SuspendedView>
                <RolePage />
              </SuspendedView>
            }
          />
          <Route
            path='employee/*'
            element={
              <SuspendedView>
                <EmployeePage />
              </SuspendedView>
            }
          />

          <Route
            path='department/*'
            element={
              <SuspendedView>
                <DepartmentPage />
              </SuspendedView>
            }
          />
          <Route
            path='category/*'
            element={
              <SuspendedView>
                <CategoryPage />
              </SuspendedView>
            }
          />
          <Route
            path='scheme/*'
            element={
              <SuspendedView>
                <SchemePage />
              </SuspendedView>
            }
          />
          <Route
            path='makkal/*'
            element={
              <SuspendedView>
                <MakkalPage />
              </SuspendedView>
            }
          />
          <Route
            path='grievances/*'
            element={
              <SuspendedView>
                <GrievancesPage />
              </SuspendedView>
            }
          />
          <Route
            path='page-access/*'
            element={
              <SuspendedView>
                <RoleaccessPage />
              </SuspendedView>
            }
          />
          <Route
            path='profile/*'
            element={
              <SuspendedView>
                <AccountPage />
              </SuspendedView>
            }
          />
          <Route
            path='changePassword/*'
            element={
              <SuspendedView>
                <PasswordPage />
              </SuspendedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

const SuspendedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export {PrivateRoutes}
