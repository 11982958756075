// @ts-nocheck
import {Column} from 'react-table'
import {GrievancesInfoCell} from './GrievancesInfoCell'
import {GrievancesTwoStepsCell} from './GrievancesTwoStepsCell'
import {GrievancesActionsCell} from './GrievancesActionsCell'
import {GrievancesCustomHeader} from './GrievancesCustomHeader'
import {Grievances} from '../../core/_models'

const GrievancesColumns: ReadonlyArray<Column<Grievances>> = [
  {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Grievances Id' className='min-w-125px' />
    ),
    id: 'GrievancesID',
    Cell: ({...props}) => <GrievancesInfoCell grievances={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Department' className='min-w-125px' />
    ),
    id: 'department',
    Cell: ({...props}) => <GrievancesInfoCell grievances={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Category' className='min-w-125px' />
    ),
    accessor: 'category',
  },

  {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Grievance Type' className='min-w-125px' />
    ),
    accessor: 'grievancetype',
  },
   {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Grievance' className='min-w-125px' />
    ),
    accessor: 'grievance',
  },
  {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <GrievancesInfoCell grievances={props.data[props.row.index]} />,
  },
 {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='City' className='min-w-125px' />
    ),
    accessor: 'city',
  },
   {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Zone' className='min-w-125px' />
    ),
    accessor: 'zone',
  },
   {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Notes' className='min-w-125px' />
    ),
    accessor: 'notes',
  },
   {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Priority' className='min-w-125px' />
    ),
    accessor: 'proirity',
  },
  {
    Header: (props) => (
      <GrievancesCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <GrievancesActionsCell grievances={props.data[props.row.index]} />,
  },
]

export {GrievancesColumns}
