import React from 'react'

interface State {
  primaryId: string
  name: string
  
  editclicked: boolean
}

interface SetState {
  (newState: State): void
}

interface ContextValue {
  state: State
  setState: SetState
}

export const MyContext = React.createContext<ContextValue>({
  state: {primaryId: '', name: '', editclicked: false},
  setState: () => {},
})
