import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Department, DepartmentQueryResponse} from './_models'
// import {getToken} from '../../../../auth/core/AuthHelpers'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URL

//Department API URL
const CREATE_Department_URL = `${API_URL}/department/add`
const GET_Department_URL = `${API_URL}/department/query`
const UPDATE_Department_URL = `${API_URL}/department/update`
const DELETE_Department_URL = `${API_URL}/department/delete`

// var AuthVerify = getToken.call(this)

//Department LIST
const getDepartment = (query: string): Promise<DepartmentQueryResponse> => {
  return axios
    .post(`${GET_Department_URL}?${query}`,
     // AuthVerify
    )
    .then((response: AxiosResponse<DepartmentQueryResponse>) => {
      return response.data
    })
}

//Department ADD
const createDepartment = (departmentdata: Department): Promise<Department | undefined> => {
  return axios.post(CREATE_Department_URL, departmentdata,
    //getToken()
  ).then((response: any) => response.data)
}

//Department UPDATE
const updateDepartment = (name: any, id: any, updatedBy: any) => {
  return axios.put(`${UPDATE_Department_URL}/${id}`, { name, updatedBy },
    //getToken()
  ).then((response) => {
    return response.data
  })
}

//Department DELETE
const deleteDepartmentById = (id: any) => {
  return axios.post(`${DELETE_Department_URL}/${id}`,
    //AuthVerify


    ).then((response) => {
    return response.data
  })
}

export {getDepartment, createDepartment, updateDepartment, deleteDepartmentById}
