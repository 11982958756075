import {useListView} from '../../core/ListViewProvider'
import {UsersListSearchComponent} from './CategoryListSearchComponent'
import {CategoryListToolbar} from './CategoryListToolbar'

const CategoryListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      {/* Search bar */}
      <UsersListSearchComponent />
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <CategoryListToolbar />
        {/* end::Group actions */}
      </div>
    </div>
  )
}

export {CategoryListHeader}
