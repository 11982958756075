// @ts-nocheck
import {Column} from 'react-table'
import {MakkalInfoCell} from './MakkalInfoCell'
import {MakkalActionsCell} from './MakkalActionsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {Makkal} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<Makkal>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='S.No' className='min-w-125px' />,
    accessor: 'serialNo',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
    Cell: ({...props}) => <MakkalInfoCell makkal={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='link' className='min-w-125px' />,
    accessor: 'link',
    Cell: ({...props}) => <MakkalInfoCell makkal={props.data[props.row.index]} />,
  },


  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <MakkalActionsCell makkal={props.data[props.row.index]} />,
  },
]

export {usersColumns}
