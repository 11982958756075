import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {EmployeeListWrapper} from './employee-list/EmployeeList'
import {AddEmployee} from './employee-form'
import {EditEmployeeWrapper} from './employee-form/EditEmployeeWrapper'

const employeesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Employee Management',
    path: '/employee',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EmployeesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/list'
          element={
            <>
              <PageTitle breadcrumbs={employeesBreadcrumbs}>Employee list</PageTitle>
              <EmployeeListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='list' />} />
      <Route
        path='/add'
        element={
          <>
            {' '}
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Employee Add</PageTitle>
            <AddEmployee />
          </>
        }
      />
      <Route
        path='/edit/:id'
        element={
          <>
            <PageTitle breadcrumbs={employeesBreadcrumbs}>Employee Edit</PageTitle>
            <EditEmployeeWrapper />
          </>
        }
      />
    </Routes>
  )
}

export default EmployeesPage
