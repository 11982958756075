/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

import {getrolewisesidemenu} from '../../../../../app/modules/apps/role-access/role-setting-list/core/_requests'
import {useAuth} from '../../../../../app/modules/auth/core/Auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const [sidemenu, setsidemenu] = useState<any>([])
  const {currentUser, logout} = useAuth()
  // var Localtype = localStorage.getItem('rakyat_auth_detail');
  const currentRole = currentUser?.roleId
  console.log('currentUser====', currentUser)
  console.log('currentUserroleId====', currentUser?.roleId)
  useEffect(() => {
    getrolewisesidemenu(currentRole).then((res: any) => {
      const sidemenuitems = res.data.Data
      console.log('sidemenu', sidemenuitems)
      const result = sidemenuitems.reduce(
        (acc: {[x: string]: any[]}, item: {module: {title: {subtitileName: any}}}) => {
          const sbtitleName = item.module.title.subtitileName
          if (!acc[sbtitleName]) {
            acc[sbtitleName] = []
          }
          acc[sbtitleName].push(item)
          return acc
        },
        {}
      )

      const restructuredData = Object.keys(result).map((key) => ({
        [key.toLowerCase()]: result[key],
      }))

      //  console.log("restructuredData",restructuredData);
      setsidemenu(restructuredData)
    })
  }, [])
  console.log('restructuredData', sidemenu)
  return (
    <>
      {sidemenu.map((item: any, i: any) => {
        const currentSubtitle = Object.keys(item)[0]

        return (
          <>
            <div key={i}>
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                    {currentSubtitle}
                  </span>
                </div>
              </div>
              {item[currentSubtitle].map((module: any, j: any) => (
                <SidebarMenuItem
                  key={j}
                  to={module.module?.link}
                  icon={module.module?.icon}
                  title={module.module?.name}
                  fontIcon='bi-app-indicator'
                />
              ))}
            </div>
          </>
        )
      })}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Overview</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>HR</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/role'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Role'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/employee'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Employee'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/page-access'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Page Access'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Grievance</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/department'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Department'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/category'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Category'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/grievances'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Griavance'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Grievance Schemes
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/scheme'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Scheme'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/makkal'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Makkal Sevvai Maiyam'
        fontIcon='bi-layers'
      /> */}
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}

      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='HR'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Role' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Employee' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          /> 
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='HR'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Role' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Employee' hasBullet={true} />
          <SidebarMenuItem to='/crafted/account/settings' title='Page Access' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Grievance'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Department' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Category' hasBullet={true} />
         <SidebarMenuItem to='/error/500' title='Grievance' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Schemes'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Schemes' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Makkal Sevai Maiyam' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
