import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {Grievances, GrievancesQueryResponse} from './_models'
// import {getToken} from '../../../../auth'

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//ROLE API URL
const CREATE_Grievances_URL = `${API_URL}/grievances/add`
const GET_Grievances_URL = `${API_URL}/grievances/query`
const VIEW_Grievances_URL = `${API_URL}/grievances/view`
const UPDATE_Grievances_URL = `${API_URL}/grievances/update`
const DELETE_Grievances_URL = `${API_URL}/grievances/delete`
const GET_ROLE_URL = `${API_URL}/grievances/getRole`

//AuthVerify
// var AuthVerify = getToken.call(this)

//Grievances LIST
const getGrievances = (query: string): Promise<GrievancesQueryResponse> => {
  return axios
    .get(`${GET_Grievances_URL}?${query}`,
     // getToken()
    )
    .then((response: AxiosResponse<GrievancesQueryResponse>) => {
      return response.data
    })
}

//Grievances ADD
const createGrievances = (data: FormData): Promise<Grievances | undefined> => {
  return axios.post(CREATE_Grievances_URL, data,
    //getToken()
  ).then((response: any) => response.data)
}

//Grievances VEIW
const viewGrievances = (id: any) => {
  return axios.get(`${VIEW_Grievances_URL}/${id}`,
    //getToken()
  ).then((response) => {
    return response.data
  })
}

//Grievances UPDATE
const updateGrievances = (data: FormData, id: any) => {
  console.log('reqData', data)
  return axios.put(`${UPDATE_Grievances_URL}/${id}`, data,
   // getToken()
  ).then((response) => {
    return response.data
  })
}

//Grievances DELETE
const deleteGrievancesById = (id: any) => {
  return axios.post(`${DELETE_Grievances_URL}/${id}`,
    //AuthVerify
  ).then((response) => {
    return response.data
  })
}

//GET ROLE
const getRole = async () => {
  const res = await axios.get(`${GET_ROLE_URL}`,
  //  getToken()
  )
  console.log('grievanceList=>', res)
  return res
}

export {getGrievances, viewGrievances, deleteGrievancesById, getRole}
