import {RoleListHeader} from './components/header/RoleListHeader'
import {RoleTable} from './table/RoleTable'
import {KTCard} from '../../../../../_metronic/helpers'
import {AddRole, EditRole} from '../role-form'
import {useContext} from 'react'
import {MyContext} from './ContextFile'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ContextProvider} from './ContextProvider'
// import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
// import {AlertBox} from '../../../../../_metronic/layout/components/alert-message/AlertBox'
import {AlertContext} from '../../../../../_metronic/layout/components/alert-context'
import {AlertBox} from '../../../../../_metronic/layout/components/alert-message'
const RoleAdd = () => {
  const {state, setState} = useContext(MyContext)

  return (
    <>
      <KTCard>{state.editclicked === true ? <EditRole /> : <AddRole />}</KTCard>
    </>
  )
}

const RoleList = () => {
  return (
    <>
      <KTCard>
        <RoleListHeader />
        <RoleTable />
      </KTCard>
    </>
  )
}

const RoleMain = () => {
  const {show, message, type} = useContext(AlertContext)
  return (
    <div className='row'>
      {show && <AlertBox type={type === '1' ? 'success' : 'error'} message={message} />}
      <div className='col-lg-5'>
        <RoleAdd />
      </div>
      <div className='col-lg-7'>
        <RoleList />
      </div>
    </div>
  )
}

const RoleListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContextProvider>
          <RoleMain />
        </ContextProvider>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RoleListWrapper}
